@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

body, html {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  height: 100%;
}

.signup-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #072927 0%, #0A3D3A 100%);
  position: relative;
  overflow: hidden;
  padding: 40px 0;
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}

.semi-transparent-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 95%;
  height: 95%;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 20px;
  backdrop-filter: blur(15px);
  z-index: 1;
}

.icon-holder {
  position: absolute;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: all 0.3s ease;
}

.icon-holder img {
  width: 60%;
  height: 60%;
  object-fit: contain;
  opacity: 0.7;
  transition: all 0.3s ease;
}

.icon-holder:hover img {
  opacity: 1;
  transform: scale(1.1);
}

.icon-holder1 { width: 150px; height: 150px; top: 10%; left: 5%; }
.icon-holder2 { width: 200px; height: 200px; bottom: 15%; right: 5%; }
.icon-holder3 { width: 180px; height: 180px; top: 20%; right: 10%; }
.icon-holder4 { width: 120px; height: 120px; bottom: 25%; left: 15%; }
.icon-holder5 { width: 160px; height: 160px; top: 40%; left: 20%; }

.signup-card {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(20px);
  border-radius: 20px;
  padding: 40px;
  width: 100%;
  max-width: 800px;
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  z-index: 2;
  position: relative;
}

.logo {
  width: 120px;
  margin-bottom: 30px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.signup-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.input-group {
  margin-bottom: 15px;
}

.input-group.full-width {
  grid-column: 1 / -1;
}

label {
  display: block;
  color: #FFFFFF;
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 500;
}

input, select, textarea {
  width: 100%;
  padding: 12px 15px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  color: #FFFFFF;
  font-size: 14px;
  transition: all 0.3s ease;
  box-sizing: border-box;
}

input::placeholder, select::placeholder, textarea::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

input:focus, select:focus, textarea:focus {
  background: rgba(255, 255, 255, 0.15);
  border-color: rgba(255, 255, 255, 0.3);
  outline: none;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1);
}

textarea {
  height: 100px;
  resize: vertical;
}

.hero-button {
  grid-column: 1 / -1;
  align-items: center;
  background-image: linear-gradient(144deg, #1B4D3E, #072927 50%, #0A3A34);
  border: 0;
  border-radius: 50px;
  box-shadow: rgba(7, 41, 39, 0.2) 0 15px 30px -5px;
  box-sizing: border-box;
  color: #FFFFFF;
  display: flex;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 600;
  justify-content: center;
  line-height: 1em;
  max-width: 100%;
  min-width: 140px;
  padding: 16px 24px;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
}

.hero-button:hover {
  background-image: linear-gradient(144deg, #2C7A63, #0A3D3A 50%, #0E5852);
  transform: translateY(-2px);
}

.login-prompt {
  color: #FFFFFF;
  font-size: 14px;
  text-align: center;
  margin-top: 25px;
}

.login-prompt a {
  color: #A0E4E2;
  text-decoration: underline;
  transition: color 0.3s ease;
}

.login-prompt a:hover {
  color: #FFFFFF;
}

@media (max-width: 768px) {
  .signup-form {
    grid-template-columns: 1fr;
  }

  .signup-card {
    padding: 30px;
    max-width: 90%;
  }

  .icon-holder {
    display: none;
  }
}