@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: Arial, sans-serif;
    background-color: #ffffff;
    color: #333;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px 40px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header .logo {
    font-size: 24px;
    font-weight: bold;
}

.header nav {
    display: flex;
    gap: 20px;
}

.header nav a {
    text-decoration: none;
    color: #333;
    font-size: 16px;
}

.header .login-btn {
    padding: 10px 20px;
    border: 1px solid #333;
    border-radius: 5px;
    text-decoration: none;
    color: #333;
    background-color: #fff;
}

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 80px);
    padding: 20px;
}

.form-wrapper {
    display: flex;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.login-form, .demo-request {
    padding: 40px;
    width: 100%;
    max-width: 450px;
}

.login-form {
    border-right: 1px solid #e0e0e0;
}

.login-form h2, .demo-request h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
}

.login-form input[type="email"],
.login-form input[type="password"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.password-wrapper {
    display: flex;
    align-items: center;
    position: relative;
}

.password-wrapper .toggle-password {
    position: absolute;
    right: 10px
;
    background: none;
    border: none;
    color: #333;
    cursor: pointer;
}

.login-form button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #c0c0c0;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
}

.login-form .divider {
    text-align: center;
    margin: 20px 0;
    font-size: 14px;
    color: #ccc;
}

.google-btn {
    background-color: #fff;
    color: #333;
    border: 1px solid #ccc;
    padding: 10px;
    width: 100%;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.demo-request ul {
    list-style: none;
    padding: 0;
    color: #333;
}

.demo-request li {
    margin-bottom: 10px;
}

.demo-request button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #333;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
}

.footer {
    background-color: #f1f1f1;
    padding: 20px;
    display: flex;
    justify-content: space-around;
    border-top: 1px solid #e0e0e0;
    color: #333;
}

.footer div {
    flex: 1;
}

.footer h3 {
    margin-bottom: 10px;
}

.footer ul {
    list-style: none;
    padding: 0;
}

.footer li {
    margin-bottom: 5px;
}

.footer {
    text-align: center;
    padding: 20px;
}

.footer p {
    margin: 0;
    font-size: 14px;
    color: #999;
}

.footer a {
    color: #999;
    text-decoration: none;
}

.image-gallery-wrapper {
    width: 80%;
    max-width: fit-content;
    margin-left: 8%;
    margin-top: 20px;
    position: relative;
}

.image-gallery-slide img {
    width: 50%;
    height: 50%;
    object-fit: cover;
    max-height: 300px; 
    border-radius: 12px;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
    height: 60px; 
    width: 25px; 
}

.itemDetail-container {
    max-width: -webkit-fill-available;
    margin: 0 auto;
    padding: 27px;
}

