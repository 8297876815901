.app-layout {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

.main-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}

.main-form-area {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  background-color: #072927;
}

.create-list-item-form {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.form-container {
  display: flex;
  flex: 1;
  background-color: #072927;
  border-radius: 8px;
  overflow: hidden;
}

.form-sidebar {
  width: 300px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 48px;
  margin: 40px 0 20px 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sidebar-steps {
  flex-grow: 1;
}

.logo-container {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.logo-image {
  width: 400px;
  height: auto;
  max-height: 120px;
  object-fit: contain;
}

.logo-placeholder {
  width: 100%;
  height: 60px;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  margin-top: 20px;
}

.form-content {
  flex: 1;
  background-color: #072927;
  padding: 40px;
  overflow-y: auto;
}

.form-paper {
  padding: 40px;
  background-color: #ffffff;
  border-radius: 48px !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid #072927;
}

.form-header h4 {
  font-size: 28px;
  font-weight: bold;
  color: #072927;
}

.form-navigation {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #072927;
}

.sidebar-step {
  display: flex;
  align-items: center;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.sidebar-step:hover {
  background-color: rgba(25, 118, 210, 0.05);
}

.sidebar-step.active {
  background-color: rgba(25, 118, 210, 0.1);
}

.sidebar-step.confirmed .step-number {
  background-color: #4caf50;
  color: white;
}

.step-number {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #072927;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 18px;
  margin-right: 16px;
}

.sidebar-step.active .step-number {
  background-color: #072927;
  color: white;
}

.step-content {
  display: flex;
  align-items: center;
}

.step-content svg {
  margin-right: 12px;
  font-size: 24px;
  color: #072927;
}

.sidebar-step.active .step-content svg {
  color: #072927;
}

.step-text {
  display: flex;
  flex-direction: column;
}

.modernform-button {
  background-image: linear-gradient(144deg, #072927, #072927 50%, #0A3A34);
  border: 0;
  border-radius: 50px;
  box-shadow: rgba(7, 41, 39, 0.2) 0 15px 30px -5px;
  box-sizing: border-box;
  color: #FFFFFF;
  display: flex;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 600;
  justify-content: center;
  line-height: 1em;
  max-width: 100%;
  min-width: 140px;
  padding: 16px 24px;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s ease;
}

.modernform-button:hover {
  background-image: linear-gradient(144deg, #1F5A4A, #0A3D3A 50%, #0E4640);
}

.modernform-button:active {
  background-image: linear-gradient(144deg, #173D33, #051E1D 50%, #072724);
}

.modernform-button:disabled {
  background-image: linear-gradient(144deg, #3A3A3A, #2A2A2A 50%, #333333);
  cursor: not-allowed;
}

.selection-button {
  height: 200px;
  font-size: 24px;
  text-transform: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px !important;
  background-color: #FFFFFF !important;
  color: #072927 !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
  transition: all 0.3s ease !important;
  border: 1px solid #1B4D3E !important;
}

.selection-button:hover {
  background-color: #E0E6ED !important;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15) !important;
}

.selection-button.selected {
  background-color: #072927 !important;
  color: white !important;
}

.selection-button svg {
  font-size: 48px;
  margin-bottom: 16px;
}

.form-section {
  margin-bottom: 30px;
}

.form-input {
  margin-bottom: 20px !important;
}

.MuiFormControl-root {
  width: 100%;
  margin-bottom: 24px !important;
}

.MuiInputBase-root {
  border-radius: 38px !important;
  background-color: #F0F4F8 !important;
  padding: 12px 16px !important;
  transition: all 0.3s ease !important;
  min-height: 56px !important;
  box-sizing: border-box !important;
  border: 1px solid #072927 !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05) !important;
}

.MuiInputBase-root:hover {
  border-color: #072927 !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
}

.MuiInputBase-root.Mui-focused {
  border-color: #072927 !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15) !important;
}

.MuiInputBase-input {
  padding: 0 !important;
  font-family: 'Poppins', sans-serif !important;
  font-size: 16px !important;
  color: #072927 !important;
  border-bottom: none !important;
}

.MuiInputLabel-root {
  position: relative !important;
  transform: none !important;
  font-family: 'Poppins', sans-serif !important;
  font-size: 14px !important;
  color: #072927 !important;
  margin-bottom: 8px !important;
  display: block !important;
  top: 0 !important;
  left: 0 !important;
}

.MuiSelect-select {
  padding-right: 32px !important;
}

.MuiMenuItem-root {
  font-family: 'Poppins', sans-serif !important;
  font-size: 16px !important;
  padding: 12px 16px !important;
}

.MuiMenuItem-root:hover {
  background-color: #E0E6ED !important;
}

.MuiMenuItem-root.Mui-selected {
  background-color: #D0D9E0 !important;
}

.MuiInputAdornment-root {
  margin-right: 12px !important;
}

.keyword-chip {
  margin: 5px;
  border-radius: 50px !important;
  background-color: #F0F4F8 !important;
  color: #072927 !important;
  font-family: 'Poppins', sans-serif !important;
  font-size: 14px !important;
}

.dropzone {
  border: 2px dashed #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-bottom: 20px;
}

.dropzone:hover, .dropzone.active {
  border-color: #072927;
  background-color: rgba(25, 118, 210, 0.05);
}

.review-section {
  background-color: #FFFFFF;
  border-radius: 22px !important;
  padding: 20px;
}

.review-header {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #072927;
}

.review-subheader {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #072927;
  border-bottom: 1px solid #072927;
  padding-bottom: 8px;
}

.review-paper {
  padding: 20px;
  margin-bottom: 20px;
}

.review-image {
  width: 100%;
  height: auto;
  border-radius: 4px;
}

.logistics-button {
  height: 100px;
  font-size: 14px;
  text-transform: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px !important;
  background-color: #F0F4F8 !important;
  color: #072927 !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
  transition: all 0.3s ease !important;
  border: 1px solid #072927 !important;
}

.logistics-button:hover {
  background-color: #E0E6ED !important;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15) !important;
}

.logistics-button.selected {
  background-color: #072927 !important;
  color: white !important;
}

.logistics-button svg {
  font-size: 32px;
  margin-bottom: 8px;
}

.success-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 32px;
  text-align: center;
  outline: none;
}

.success-icon {
  font-size: 64px;
  color: #4caf50;
  margin-bottom: 16px;
}

.my-listings-button {
  margin-top: 24px;
}

@media (max-width: 768px) {
  .app-layout {
    flex-direction: column;
  }

  .main-content {
    height: calc(100vh - 64px);
  }

  .main-form-area {
    padding: 10px;
  }

  .form-container {
    flex-direction: column;
  }

  .form-sidebar {
    width: 100%;
    height: auto;
    padding: 10px;
    margin: 20px;
  }

  .form-content {
    padding: 20px;
  }

  .form-paper {
    padding: 20px;
  }

  .modernform-button {
    padding: 12px 20px;
    font-size: 16px;
  }

  .selection-button {
    height: 150px;
    font-size: 20px;
  }

  .logistics-button {
    height: 80px;
    font-size: 12px;
  }

  .logistics-button svg {
    font-size: 24px;
  }
}