@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

body, html {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  height: 100%;
}

.login-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #072927 0%, #0A3D3A 100%);
  position: relative;
  overflow: hidden;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}

.semi-transparent-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 20px;
  backdrop-filter: blur(10px);
  z-index: 1;
}

.icon-holder {
  position: absolute;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.icon-holder img {
  width: 70%;
  height: 70%;
  object-fit: contain;
}

.icon-holder1 { width: 150px; height: 150px; top: 10%; left: 10%; }
.icon-holder2 { width: 200px; height: 200px; bottom: 15%; right: 5%; }
.icon-holder3 { width: 180px; height: 180px; top: 20%; right: 15%; }
.icon-holder4 { width: 120px; height: 120px; bottom: 25%; left: 20%; }
.icon-holder5 { width: 160px; height: 160px; top: 40%; left: 30%; }

.login-card {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(20px);
  border-radius: 20px;
  padding: 40px;
  width: 100%;
  max-width: 420px;
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  z-index: 2;
  position: relative;
}

.logo {
  width: 120px;
  margin-bottom: 30px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.input-group {
  margin-bottom: 25px;
}

label {
  display: block;
  color: #FFFFFF;
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 500;
}

input {
  width: 100%;
  padding: 18px 20px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  color: #CCCCCC !important;
  font-size: 16px;
  transition: all 0.3s ease;
  box-sizing: border-box;
}

input::placeholder {
  color: rgba(255, 255, 255, 0.5);
  padding-left: 5px;
}

input:focus {
  background: rgba(255, 255, 255, 0.15);
  border-color: rgba(255, 255, 255, 0.3);
  outline: none;
  padding-left: 25px;
}

.forgot-password {
  display: block;
  text-align: right;
  color: #FFFFFF;
  font-size: 14px;
  text-decoration: none;
  margin-bottom: 25px;
  transition: color 0.3s ease;
}

.forgot-password:hover {
  color: #A0E4E2;
}

.hero-button {
  align-items: center;
  background-image: linear-gradient(144deg, #1B4D3E, #072927 50%, #0A3A34);
  border: 0;
  border-radius: 50px;
  box-shadow: rgba(7, 41, 39, 0.2) 0 15px 30px -5px;
  box-sizing: border-box;
  color: #FFFFFF;
  display: flex;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 600;
  justify-content: center;
  line-height: 1em;
  max-width: 100%;
  min-width: 140px;
  padding: 16px 24px;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
}

.hero-button:hover {
  background-image: linear-gradient(144deg, #1F5A4A, #0A3D3A 50%, #0E4640);
}

.register-prompt {
  color: #FFFFFF;
  font-size: 14px;
  text-align: center;
  margin-top: 25px;
}

.register-prompt a {
  color: #A0E4E2;
  text-decoration: underline;
  transition: color 0.3s ease;
}

.register-prompt a:hover {
  color: #FFFFFF;
}