.rensDashboardLayout {
  display: flex;
  min-height: 100vh;
  background-color: #072927;
}

.mainContent {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.toggleContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 16px;
  background-color: #0B3D3A;
}

.dashboardToggle {
  margin-right: 8px;
}

.toggleLabel {
  color: white;
  font-size: 14px;
}

.dashboardContainer {
  flex-grow: 1;
  padding: 16px;
  overflow-y: auto;
  background-color: #072927;
}

.dashboardContent {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.stepContainer {
  background-color: #FFFFFF;
  border-radius: 16px;
  padding: 24px;
  margin-bottom: 24px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.secondStepContainer {
  max-width: 1200px;
}

.stepHeader {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.stepLogo {
  height: 110px;
  margin-right: 16px;
  filter: none;
}

.metricsGroup {
  background-color: #0F4A46 !important;
  border-radius: 16px;
  padding: 24px;
  margin-bottom: 16px;
  width: 100%;
}

.secondStepContainer .metricsGroup {
  padding: 16px;
}

.metricCard {
  background-color: #165853 !important;
  border-radius: 16px;
  height: 100%;
  box-shadow: 0 1px 3px rgba(0,0,0,0.05);
  transition: box-shadow 0.3s ease;
  position: relative;
}

.secondStepContainer .metricCard {
  padding: 12px;
}

.metricCard:hover {
  box-shadow: 0 2px 6px rgba(0,0,0,0.1);
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-bottom: 12px;
  color: #0F4A46;
  background-color: #FFFFFF;
}

.metricTitle {
  font-size: 14px;
  color: #A0AEC0;
  margin-bottom: 8px;
}

.metricValue {
  font-size: 18px;
  font-weight: 600;
  color: white;
}

.chartContainer {
  background-color: #0F4A46 !important;
  border-radius: 16px;
  padding: 24px;
  margin-top: 0px;
  height: 100%;
  width: 100%;
}

.secondStepContainer .chartContainer {
  padding: 16px;
  height: auto;
}

.chartTitle {
  color: white;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
}

.perEmployeeEmissions {
  background-color: #072927;
  height: 100%;
}

.perEmployeeEmissions .metricTitle,
.perEmployeeEmissions .metricValue {
  color: #FFFFFF;
}

.indicatorContainer {
  position: absolute;
  bottom: 8px;
  right: 8px;
  display: flex;
  gap: 8px;
}

.indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.3);
  border: none;
  padding: 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.indicator.active {
  background-color: white;
}

/* Responsive adjustments */
@media (max-width: 960px) {
  .dashboardContainer {
    padding: 12px;
  }

  .stepContainer {
    padding: 20px;
  }

  .metricsGroup {
    padding: 20px;
  }

  .chartContainer {
    padding: 20px;
  }

  .metricValue {
    font-size: 16px;
  }
}

@media (max-width: 600px) {
  .dashboardContainer {
    padding: 8px;
  }

  .stepContainer {
    padding: 16px;
  }

  .metricsGroup {
    padding: 16px;
  }

  .chartContainer {
    padding: 16px;
  }

  .metricValue {
    font-size: 14px;
  }

  .toggleContainer {
    flex-direction: column;
    align-items: flex-end;
  }

  .toggleLabel {
    margin-top: 8px;
  }
}

.metricsGroup,
.chartContainer {
  padding: 24px;
}

.metricCard .MuiCardContent-root {
  padding: 24px;
}

@media (max-width: 960px) {
  .stepContainer,
  .metricsGroup,
  .chartContainer,
  .metricCard .MuiCardContent-root {
    padding: 20px;
  }
}

@media (max-width: 600px) {
  .stepContainer,
  .metricsGroup,
  .chartContainer,
  .metricCard .MuiCardContent-root {
    padding: 16px;
  }
}

.secondStepContainer .MuiGrid-item {
  padding: 8px;
}

.secondStepContainer .metricCard .MuiCardContent-root {
  padding: 16px;
}.rensDashboardLayout {
  display: flex;
  min-height: 100vh;
  background-color: #072927;
}

.mainContent {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.toggleContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 16px;
  background-color: #0B3D3A;
}

.dashboardToggle {
  margin-right: 8px;
}

.toggleLabel {
  color: white;
  font-size: 14px;
}

.dashboardContainer {
  flex-grow: 1;
  padding: 16px;
  overflow-y: auto;
  background-color: #072927;
}

.dashboardContent {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.stepContainer {
  background-color: #FFFFFF;
  border-radius: 16px;
  padding: 24px;
  margin-bottom: 24px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.secondStepContainer {
  max-width: 1200px;
}

.stepHeader {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.stepLogo {
  height: 110px;
  margin-right: 16px;
  filter: none;
}

.metricsGroup {
  background-color: #0F4A46 !important;
  border-radius: 16px;
  padding: 24px;
  margin-bottom: 16px;
  width: 100%;
}

.secondStepContainer .metricsGroup {
  padding: 16px;
}

.metricCard {
  background-color: #165853 !important;
  border-radius: 16px;
  height: 100%;
  box-shadow: 0 1px 3px rgba(0,0,0,0.05);
  transition: box-shadow 0.3s ease;
  position: relative;
}

.secondStepContainer .metricCard {
  padding: 12px;
}

.metricCard:hover {
  box-shadow: 0 2px 6px rgba(0,0,0,0.1);
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-bottom: 12px;
  color: #0F4A46;
  background-color: #FFFFFF;
}

.metricTitle {
  font-size: 14px;
  color: #A0AEC0;
  margin-bottom: 8px;
}

.metricValue {
  font-size: 18px;
  font-weight: 600;
  color: white;
}

.chartContainer {
  background-color: #0F4A46 !important;
  border-radius: 16px;
  padding: 24px;
  margin-top: 0px;
  height: 100%;
  width: 100%;
}

.secondStepContainer .chartContainer {
  padding: 16px;
  height: auto;
}

.chartTitle {
  color: white;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
}

.perEmployeeEmissions {
  background-color: #072927;
  height: 100%;
}

.perEmployeeEmissions .metricTitle,
.perEmployeeEmissions .metricValue {
  color: #FFFFFF;
}

.indicatorContainer {
  position: absolute;
  bottom: 8px;
  right: 8px;
  display: flex;
  gap: 8px;
}

.indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.3);
  border: none;
  padding: 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.indicator.active {
  background-color: white;
}

/* Responsive adjustments */
@media (max-width: 960px) {
  .dashboardContainer {
    padding: 12px;
  }

  .stepContainer {
    padding: 20px;
  }

  .metricsGroup {
    padding: 20px;
  }

  .chartContainer {
    padding: 20px;
  }

  .metricValue {
    font-size: 16px;
  }
}

@media (max-width: 600px) {
  .dashboardContainer {
    padding: 8px;
  }

  .stepContainer {
    padding: 16px;
  }

  .metricsGroup {
    padding: 16px;
  }

  .chartContainer {
    padding: 16px;
  }

  .metricValue {
    font-size: 14px;
  }

  .toggleContainer {
    flex-direction: column;
    align-items: flex-end;
  }

  .toggleLabel {
    margin-top: 8px;
  }
}

.metricsGroup,
.chartContainer {
  padding: 24px;
}

.metricCard .MuiCardContent-root {
  padding: 24px;
}

@media (max-width: 960px) {
  .stepContainer,
  .metricsGroup,
  .chartContainer,
  .metricCard .MuiCardContent-root {
    padding: 20px;
  }
}

@media (max-width: 600px) {
  .stepContainer,
  .metricsGroup,
  .chartContainer,
  .metricCard .MuiCardContent-root {
    padding: 16px;
  }
}

.secondStepContainer .MuiGrid-item {
  padding: 8px;
}

.secondStepContainer .metricCard .MuiCardContent-root {
  padding: 16px;
}