.card {
    max-width: 24rem;
    background: #0A3D3A;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    font-family: Arial, sans-serif;
    transition: all 0.3s ease;
  }
  
  .card:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  .cardContent {
    padding: 1.5rem;
  }
  
  .title {
    font-size: 1.25rem;
    font-weight: 700;
    color: #FFFFFF;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    border-bottom: 2px solid #FFFFFF;
    padding-bottom: 10px;
  }
  
  .titleIcon {
    margin-right: 10px;
  }
  
  .section {
    margin-bottom: 15px;
    padding: 10px 15px;
    background-color: #FFFFFF;
    border-radius: 8px;
  }
  
  .sectionHeader {
    font-size: 0.9rem;
    font-weight: 600;
    color: #0A3D3A;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
  }
  
  .headerIcon {
    margin-right: 5px;
  }
  
  .detailsList {
    list-style-type: none;
    padding-left: 0;
  }
  
  .detailsList li {
    margin-bottom: 5px;
    word-break: break-word;
    display: flex;
    align-items: flex-start;
  }
  
  .label {
    font-weight: 600;
    color: #0A3D3A;
    margin-right: 5px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    min-width: 120px;
  }
  
  .labelIcon {
    margin-right: 5px;
  }
  
  .value {
    font-size: 1rem;
    color: #4A4A4A;
    line-height: 1.5;
    flex: 1;
  }
  
  .buttonContainer {
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding: 1.5rem;
    background-color: #0A3D3A;
  }
  
  .button {
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
    transition: all 0.3s ease;
    border: none;
    color: white;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    min-width: 120px;
  }
  
  .acceptButton {
    background: linear-gradient(144deg, #1B4D3E, #0A3D3A);
  }
  
  .acceptButton:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(10, 61, 58, 0.4);
  }
  
  .rejectButton {
    background: linear-gradient(144deg, #D9534F, #C9302C);
  }
  
  .rejectButton:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(229, 62, 62, 0.4);
  }
  
  @media (max-width: 640px) {
    .card {
      max-width: 100%;
      margin: 0 1rem;
    }
  
    .buttonContainer {
      flex-direction: column;
      align-items: stretch;
    }
  
    .button {
      width: 100%;
    }
  }